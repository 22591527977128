import React from "react";
import sectionsInfo from "../files/Sections_en.json";
import gamesInfo from "../files/Games_en.json";

const LandGames = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          {sectionsInfo.map(function (item, i) {
            if (item.type === "games" && item.subId === "") {
              return [
                <h2 key={i} className="section-heading">
                  {item.title}
                </h2>,
                <h3 key={i + 1} className="section-subheading text-muted">
                  {item.text}
                </h3>,
              ];
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div className="row text-center">
        {gamesInfo.map(function (obj, i) {
          if (obj.status === "Active") {
            return (
              <div key={i} className="col-md-4 col-sm-6">
                <span className="img-responsive img-centered">
                  <a
                    href={obj.url}
                    title={obj.title}
                    alt={obj.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={obj.picture.substring(1, 30)}
                      alt={obj.title}
                      width={obj.picPos}
                      height={obj.picId}
                    />
                  </a>
                </span>
                <h4 className="skills-heading">{obj.title}</h4>
                <p className="text-muted">{obj.text}</p>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>

      <br />
      <div className="row text-center">
        <div className="col-md-4 col-sm-6"></div>
        <div className="col-md-4 col-sm-6">
          <h4 className="skills-heading">
            <i>More Games comming soon ...</i>
          </h4>
          <p className="text-muted"></p>
        </div>
      </div>
    </div>
  );
};

export default LandGames;
